import { Header } from 'semantic-ui-react'
import './Product.css'
// import bgGround from '../../images/micro-controller-bg.png'
import { ProductsTab } from './ProductsTab'

export const Product = () => {
  return (
    <div className='product' style={{
      backgroundColor: 'rgba(70, 61, 163, 1)'
    }}>
      <div
        className='product__top'
        
      >
        <Header as={'h1'} size='huge'>
      Features
        </Header>
      </div>
      <div className='product__bottom'>
        <ProductsTab/>
      </div>
      
    </div>
  )
}