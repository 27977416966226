import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useEffect, useState } from 'react';
import { Button, Dimmer, Form, Header, Loader, Message, TextArea } from 'semantic-ui-react';
import './Contact.css';

export const Contact = () => {
  const [showErrMsg, setShowErrMsg] = useState<string | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [form, setForm] = useState<{
    fullName: string,
    contactNo: string,
    emailId: string,
    about: string
  }>({
    fullName: "",
    contactNo: "",
    emailId: "",
    about: "",
  })

  useEffect(() => {
    if (showSuccessMsg) {
      setTimeout(() => {
        setShowSuccessMsg(false);
      },2000)
    }
  }, [showSuccessMsg])
  const handleChange = (e: any, {value, name}: any) => {
    setForm({
      ...form,
      [name]: value
    })
  }

  const submitForm = async () => {
    let url = process.env.REACT_APP_FORM_URL;
    if (!url) {
      return;
    }

    url = url.replace("{{FullName}}", form.fullName);
    url = url.replace("{{ContactNo}}", form.contactNo);
    url = url.replace("{{EmailID}}", form.emailId);
    url = url.replace("{{About}}", form.about);
    setShowLoader(true);
    const iframe = document.createElement('iframe')
    iframe.src = url;
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    setTimeout(() => {
      document.body.removeChild(iframe);
      setShowLoader(false);
      setForm({
        fullName: "",
        contactNo: "",
        emailId: "",
        about: ""  
      })
      setShowSuccessMsg(true);
    }, 1000)
  }

  const validator = (name: string, value: string) => {
    switch(name) {
      case "fullName":
        return value.length >= 3;
      case "collegeName":
        return value.length >= 3;
      case "contactNo":
        return String(value)
          .toLowerCase()
          .match(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
          );
      case "emailId":
        return String(value)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        }
  }

  const handleSubmit = async () => {
    if (form.fullName && form.about && form.contactNo && form.emailId) {
      if (!validator("fullName", form.fullName)) {
        setShowErrMsg("Full Name should be atleast 3 characters");
        return;
      }

     

      if (!validator("contactNo", form.contactNo)) {
        setShowErrMsg("Invalid Contact No");
        return;
      }

      if (!validator("emailId", form.emailId)) {
        setShowErrMsg("Invalid Email Id");
        return;
      }
      if (!validator("collegeName", form.about)) {
        setShowErrMsg("About should be atleast 3 characters");
        return;
      }

      setShowErrMsg(null);
      await submitForm();
    } else {
      setShowErrMsg("* fields are required")
    }
  }

  return (
    <div className='apply'>
      <div  className='apply__left1'>
      <div className='apply__left'>
        <Header as={'h1'} size='huge'>
        Contact Information
        </Header>
        <p>
          Gain enough knowledge to help scale through the  industry
        </p>
        <div className='apply-flex'>
                <div  className='apply-menu-item2'>
                  <LocalPhoneOutlinedIcon  className='icon'></LocalPhoneOutlinedIcon> 
                  <div >
                  {/* <div style={{fontSize:'12px',color:'rgba(255, 255, 255, 0.8)'}} >Tel</div> */}
                  <div  >+91 6304748719</div>
                  </div>
                </div>
                <div  className='apply-menu-item2'>
                   <EmailOutlinedIcon className='icon'></EmailOutlinedIcon>
                  
                     <div >
                  {/* <div style={{fontSize:'12px',color:'rgba(255, 255, 255, 0.8)'}} >Mail</div> */}
                  <div  >info@quinterrasoft.com</div>
                  </div>
                </div>
                <div  className='apply-menu-item2'>
                   <LocationOnOutlinedIcon className='icon'></LocationOnOutlinedIcon>
                   <div >
                  {/* <div style={{fontSize:'12px',color:'rgba(255, 255, 255, 0.8)'}} >Address</div> */}
                  <div  >‍Bangaru building, Laxmareddy Palam, 
                     Hyderabad,Telangana.</div>
                  </div>
                </div>
                </div>
      </div>
      <div className='apply__right'>
        <Form onSubmit={handleSubmit}>
          <Form.Input
            fluid
            className='input'
            label='Full Name*'
            placeholder='Full Name'
            name="fullName"
            onChange={handleChange}
            value={form.fullName}
          />
        
          <Form.Input
            fluid
            label='Contact No*'
            placeholder='Contact No'
            name="contactNo"
            onChange={handleChange}
            value={form.contactNo}
          />
          <Form.Input
            fluid label='Email Id*'
            placeholder='Email Id'
            name="emailId"
            onChange={handleChange}
            value={form.emailId}
          />
         
         <Form.Field
            control={TextArea}
            label='About'
            placeholder='...'
            name="about"
            onChange={handleChange}
            value={form.about}
          />
          {showErrMsg && <div style={{color: "red"}}>{showErrMsg}</div>}
          <Dimmer active={showLoader}>
            <Loader />
          </Dimmer>
          <div className='apply__btn'>
            <Form.Field primary control={Button}  className='button'>
              Submit
            </Form.Field>
          </div>
        </Form>
        {showSuccessMsg && <Message positive>
          <Message.Header>Application successfully submitted!!</Message.Header>
        </Message>}
      </div>
      </div>
    </div>
  )
}