import LanguageIcon from '@mui/icons-material/Language';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Lottie from "lottie-react";
import { Header } from "semantic-ui-react";
import ideationLottie from "../../images/lotties/ideation-lottie.json";
import workingLottie from "../../images/lotties/working-lottie.json";
import "./About.css";

export const About = () => {
  return (
    <>
      <div className="about">
        <div className="about-title">
          <h1>About Qshikshak</h1>
        </div>
      <div className="about-content">
      <div className="about-item">
          <div className="lottie-section">
            <Lottie animationData={workingLottie} className="lottie" loop={true} />;
          </div>
          <div className="text-section">
            <Header as={"h2"} size="large">
            Qshikshak
            </Header>
            <p>
            Qshikshak is a software developed by Quinterra Software  Solutions Pvt.Ltd. helps  Educational Institutions  function easy .
            We, the Qshikshak squad, is a bunch of highly motivated humans waking up every day with a drive of working harder and smarter 
            to enhance the student's learning experiences by connecting parents, teachers and the school with our cutting edge automation 
            solutions and mobile applications.
              <br></br>
              <br></br>
              We strive to make the Teachers focus more on academics of a child by getting rid of 
              smaller yet time consuming daily chores like marking attendance, filling up students diary etc. 
              and keeping Parents aware and involved in child's progress which eventually satisfies the purpose of enhancing a child's learning..

            </p>
          </div>
        </div>
       
        <div className="about-item">
          <div className="text-section">
            <Header as={"h2"} size="large">
              A company is as good as its team
            </Header>
            <p>
              We are an software solutions company that specializes in
             custom development.
              <br></br>
              <br></br>
              We are passionate about mobility and passionate about a clean
              environment. We’ve spent years driving innovation in mobility,
              yet we know there is still so much more to be done. We know we
              can all do better. We can create an amazing mobility
              experience while still protecting the health of our future
              generations.
            </p>
          </div>
          <div className="lottie-section">
            <Lottie animationData={ideationLottie} className="lottie" loop={true} />;
          </div>
        </div>
        <div className="about-item">
          <div className="left">
            <div className="item-title">
              <PersonOutlineIcon sx={{fontSize: "50px"}}></PersonOutlineIcon>
              <div>Culture</div>
            </div>
            <p>
              If a company is as good as the team of people who work there,
              then a team is as good as its culture. We aim to be as
              intentional as we can in how we scale both our organization
              and culture.
            </p>
          </div>
          <div className="right">
            <div className="item-title">
              <LanguageIcon sx={{fontSize: "50px"}}></LanguageIcon>
              <div>Distribution</div>
            </div>
            <p>
              If a company is as good as the team of people who work there,
              then a team is as good as its culture. We aim to be as
              intentional as we can in how we scale both our organization
              and culture.
            </p>
          </div>
        </div>
        <div className="about-item">
          <div className="why-section">
            <Header as={"h2"} size="large">
              Why Qshikshak
            </Header>
            <ul>
              <li>Builds trust and accountability.</li>
              <li>Shows unity through a shared vision.</li>
              <li>
                Helps us communicate externally who we are as a company and
                as a team.
              </li>
              <li>
                Gives us a framework to ground our decision making when
                conversations get tough.
              </li>
              <li>
                Flattens the organization. Guided by these values, critical
                and complimentary feedback alike can come from anywhere in
                the company.
              </li>
              <li>
                Sets expectations for what to expect from peers, managers,
                and leadership.
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="about-item">
          <div className="lottie-section">
            <Lottie animationData={careersLottie} className="lottie" loop={true} />;
          </div>
          <div className="text-section">
            <Header as={"h2"} size="large">
              Careers
            </Header>
            <p>
              We’re a tightly knit team of driven, mission-focused people.
              We’re growing rapidly while still nurturing our culture by
              carefully adding passionate and empathetic people.
              <br></br>
              <br></br>
              We have lots of open positions. That said, we hire people, not
              roles. Together we will carve out the right fit that gives you
              maximum joy and sets you up for success, while making sure
              it’s what we need to keep taking strides in creating a better
              web. For all of us.
              <br></br>
              <br></br>
              Can you find yourself in the above? Join us.</p>
            <div>
              <Button primary>Apply For Internship</Button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
    </>
  );
}