import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Header, MenuItem, Tab } from 'semantic-ui-react';
import logo3 from '../../images/appIcon.png';
import './Mobile.css';
// import { Tune } from '@mui/icons-material';

// import logo3 from '../../images/appIcon.png';
import SF1 from '../../images/StudentsF1.png';
// import { useSearchParams } from 'react-router-dom';

import logo from '../../images/student3d.png';

import logo2 from '../../images/attendanceIcon.png';
// import { Tune } from '@mui/icons-material';

import logoE from '../../images/Event.png';
import logoEams from '../../images/Exams.png';
import logoStaff from '../../images/Staff.png';

import logoFee1 from '../../images/Fee1.png';
import logoPayment1 from '../../images/Payment2.png';
import logoReport from '../../images/Report.png';
import Track from '../../images/Tracks.png';
import logoTrans from '../../images/Transport.png';
import logoDiary from '../../images/diary.png';
import parentapp from '../../images/parentapp.png';

import MobileAttent from '../../images/MobileAttent.png';
import Marks from '../../images/MobileMarks.png';
import Diary from '../../images/Mobilediary.png';
import Staff from '../../images/Mobileteacapp.png';
import gallery from '../../images/gallery.png';

import { useMediaQuery } from '@mui/material';
import TechAttend from '../../images/TechAttend.png';




export const MobileTab = () => {

  let isMobileView = useMediaQuery("(max-width:900px)")
  let [searchParams, setSearchParams] = useSearchParams();
  const [activetab,setActiveTab] = useState('');

  const [activeIndex,setActiveIndex] = useState(1);

  const scrollToTop = () => {    
    if (isMobileView) {
      window.scrollTo(0,500);
    } else {
      window.scrollTo(0,300);
    }
   }

   const panes = [
    {
      menuItem: (<MenuItem key='Exam' className='item1' disabled >
        {/* <img src={logo3} alt='Qshikshak' className='icon'/> */}
        <div> Parent App <br></br>
        </div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
  
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='messages' className='item1' name='Class_Student' onClick={() =>{scrollToTop()
         setActiveIndex(1)
      setSearchParams({q:'Class_Student'})
      }} >
        <img src={logo} alt='Qshikshak' className='icon' />
        <div>Student <br></br>
          Profile</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Parent App</div>
                  <div className='title'>Student Profile</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    All Student Data at one place<br></br>
                      Student Details from all the classes attended.<br></br>
                      Marks secured in subjects with graphical representation.<br></br>
                      Repord Crads one click away.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flexM'>
                  <img src={parentapp} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
              
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Fee' className='item1' name='Fee_Management' active={activetab === 'Fee_Management' } onClick={() =>{scrollToTop()
         setActiveIndex(2)
        setSearchParams({q:'Fee_Management'})
        }} >
        <img src={logoFee1} alt='Qshikshak' className='icon' />
        <div> Fee <br></br>
          Payment</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Parent App</div>
                  <div className='title'>Fee Payment</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    The Payment Gateway can be smoothly integrated in your account.<br></br>
                      Front thereby enabling you to use all the <br></br>
                      features that payment gateway has to offer.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flexM'>
                  <img src={logoPayment1} alt='Qshikshak' className='icon2' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
              
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Attendance' className='item1' name='TransportDetails' active={activetab === 'TransportDetails'}  onClick={() =>{scrollToTop()
         setActiveIndex(3)
        setSearchParams({q:'TransportDetails'})
        }} >
        <img src={logoTrans} alt='Qshikshak' className='icon' />
        <div>Trasport <br></br>
          Details</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Parent App</div>
                  <div className='title'>Transport Details</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      Realtime Bus Tracking<br></br>
                      Bus Route Optimization.<br></br>
                      SMS Alert for Student Onboarding/Deboarding Bus.<br></br>
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flexM'>
                  <img src={Track} alt='Qshikshak' className='icon2' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Events' className='item1' name='Events' active={activetab === 'Events'}  onClick={() =>{scrollToTop()
         setActiveIndex(4)
        setSearchParams({q:'Events'})
        }}>
        <img src={logoE} alt='Qshikshak' className='icon' />
        <div> Events<br></br>
          Calender</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Parent App</div>
                  <div className='title'>Events and Calender</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    Systematic organization of meetings, events,<br></br>
                    and duties to maximize the ROI you can get over time<br></br>
                    scheduling time slots<br></br>
                    Events Reflects on all the Accounts
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flexM'>
                  <img src={MobileAttent} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Exam' className='item1' name='Exams' active={activetab === 'Exams'}  onClick={() =>{scrollToTop()
         setActiveIndex(5)
        setSearchParams({q:'Exams'})
        }} >
        <img src={logoEams} alt='Qshikshak' className='icon' />
        <div> Exam <br></br>
          & Marks</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Parent App</div>
                  <div className='title'>Exam & Marks</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    Distribution and Grading of Exams<br></br>
                     Clear Reports of the studend <br></br>
                     Student marks Comparission status on the subject level  <br></br>
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flexM'>
                  <img src={Marks} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='ImageGallery' className='item1' name='ImageGallery' active={activetab === 'ImageGallery'}  onClick={() =>{scrollToTop()
         setActiveIndex(6)
        setSearchParams({q:'ImageGallery'})
        }} >
        <img src={logo3} alt='Qshikshak' className='icon' />
        <div>Image Gallery <br></br>
          </div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Parent App</div>
                  <div className='title'>Image Gallery </div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    All School gallery to View<br></br>
                    Event Gallery from each class<br></br>
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flexM'>
                  <img src={gallery} alt='Qshikshak' className='icon2' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
              
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Diary' className='item1' name='Diary' active={activetab === 'Diary'}  onClick={() =>{scrollToTop()
         setActiveIndex(7)
        setSearchParams({q:'Diary'})
        }} >
        <img src={logoDiary} alt='Qshikshak' className='icon' />
        <div>Notications <br></br>
          & Diary</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Parent App</div>
                  <div className='title'>Notications & Diary </div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    Direct Notications to parent in MobileApp<br></br>
                    View student Diary and history<br></br>
                     Easy Communicatin with Management<br></br>
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flexM'>
                  <img src={Diary} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
  
    {
      menuItem: (<MenuItem key='Exam' className='item1' disabled >
        {/* <img src={logo3} alt='Qshikshak' className='icon'/> */}
        <div>Teacher App <br></br>
        </div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
  
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='messages' className='item1'  name='StaffProfile' active={activetab === 'StaffProfile'}  onClick={() =>{scrollToTop()
         setActiveIndex(9)
        setSearchParams({q:'StaffProfile'})
        }} >
        <img src={logoStaff} alt='Qshikshak' className='icon' />
        <div> Staff <br></br>
          Profile</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Teacher App</div>
                  <div className='title'>Staff Profile</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    Staff Profile with clear details (classes,subjects of the Teacher)<br></br>
                     Easy communication with management<br></br>
                     Timetable with easy access
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flexM'>
                  <img src={Staff} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Fee' className='item1' name='StudentAttendance' active={activetab === 'StudentAttendance'}  onClick={() =>{scrollToTop()
         setActiveIndex(10)
        setSearchParams({q:'StudentAttendance'})
        }} >
        <img src={logo2} alt='Qshikshak' className='icon' />
        <div> Student <br></br>
          Attendance</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Teacher App</div>
                  <div className='title'>Student Attendance</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    Managing classes student attendance <br></br>
                     Easy to entry attendance system<br></br>
                     Student Attendance history to moniter <br></br>
                     Student Attendance report monthly<br></br>
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flexM'>
                  <img src={TechAttend} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='FeeReport' className='item1' name='AssessmentsReports' active={activetab === 'AssessmentsReports'}  onClick={() =>{scrollToTop()
         setActiveIndex(11)
        setSearchParams({q:'AssessmentsReports'})
        }}>
        <img src={logoReport} alt='Qshikshak' className='icon' />
        <div>Assessments <br></br>
           & Reports</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Teacher App</div>
                  <div className='title'>Assessment Reports</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    Class-wise marks and progess repot<br></br>
                     Clear grading and Exam schedule<br></br>
                     Notify students about exams <br></br>
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flexM'>
                  <img src={SF1} alt='Qshikshak' className='icon2' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='SpeciaclFee' className='item1'  name='EvaluationAssisst' active={activetab === 'EvaluationAssisst'}  onClick={() =>{scrollToTop()
         setActiveIndex(12)
        setSearchParams({q:'EvaluationAssisst'})
        }}>
        <img src={logo3} alt='Qshikshak' className='icon' />
        <div> Evaluation<br></br>
          Assist</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Teacher App</div>
                  <div className='title'>Evaluation Assist</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    Marks input with exam paper scanning<br></br>
                     Easy Quesion Paper prepration<br></br>
                     Assign teachers in marks entry to site<br></br>
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flexM'>
                  <img src={SF1} alt='Qshikshak' className='icon2' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
   
  
  
  ]
  
  const tabList = [
    '',
    "Class_Student",
    "Fee_Management",
    "TransportDetails",
    "Events",
    "Exams",
    "ImageGallery",
    "Diary",
    "",
    "StaffProfile",
    "StudentAttendance",
    "AssessmentsReports",
    "EvaluationAssisst",
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const q = searchParams.get('q')
    if (q){
      setActiveTab(q)
      const index = tabList.indexOf(q)
      setActiveIndex(index)
    }
  }, [searchParams, tabList])

  return (
    <Tab activeIndex={activeIndex}
      panes={panes} />
  )
}