import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import React from 'react'
import { Link } from 'react-router-dom'
import { Header, Menu, MenuItemProps } from 'semantic-ui-react'
import img2 from '../../images/FooterSection.png'
import img from '../../images/LogoMain.png'
import { TabNames } from '../Header/Header'
import './Footer.css'

export const Footer = (props: {
    activeItem: TabNames,
    handleItemClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => void
}) => {

    const scrollToTop = () => {
        window.scrollTo(0,0);
       }
       
    return (
      <footer className='footer' style={{ backgroundImage: `url(${img2})`,backgroundSize:'cover',background: 'cover' }}>
         <div className='footer__item1'>
                <img src={img} alt='optimump' height={50} />
                <div className='footer__pvt'>A Product of Quinterra Software Solutions Pvt. Ltd.</div>
                {/* <div className='footer__pvt'>Email - info@optimump.com</div> */}
                {/* <div>
                H. No : 3-4-32, Vijayasree colony, Autonagar, Hyderabad-
                500070India
                </div> */}
            </div>
        <div className='footer__top'>
        <div className='footer__item'>
                <Header as={'h4'} size='small' className='item4'>
               Reach Us
                </Header>
                <div className='footer-flex'>
                <div  className='footer-menu-item2'>
                  <LocalPhoneOutlinedIcon  className='icon'></LocalPhoneOutlinedIcon> 
                  <div >
                  <div style={{fontSize:'12px',color:'rgba(255, 255, 255, 0.8)'}} >Tel</div>
                  <div  >+91 6304748719</div>
                  </div>
                </div>
                <div  className='footer-menu-item2'>
                   <EmailOutlinedIcon className='icon'></EmailOutlinedIcon>
                  
                     <div >
                  <div style={{fontSize:'12px',color:'rgba(255, 255, 255, 0.8)'}} >Mail</div>
                  <div  >info@quinterrasoft.com</div>
                  </div>
                </div>
                <div  className='footer-menu-item2'>
                   <LocationOnOutlinedIcon className='icon'></LocationOnOutlinedIcon>
                   <div >
                  <div style={{fontSize:'12px',color:'rgba(255, 255, 255, 0.8)'}} >Address</div>
                  <div  > Laxmareddy Palam, 
                     Hyderabad,Telangana.</div>
                  </div>
                </div>
                </div>
            </div>
            <div className='footer__item'>
                <div className='footer-menu-title'><h4>Features</h4></div>
                <Menu text vertical className='footer-menu'>
                <Menu.Item
                    className='footer-menu-item'
                    name='ERP Features'
                    active={props.activeItem === TabNames.PRODUCTS}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                        props.handleItemClick(event, data);
                        scrollToTop();
                    }}
                    as={Link}
                    to='/product'
                    
                />
                 <Menu.Item
                    className='footer-menu-item'
                    name='Mobile App'
                    active={props.activeItem === TabNames.MOBILE}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                        props.handleItemClick(event, data);
                        // setOpen(false);
                        // scrollToTop();
                        scrollToTop();
                    }}
                    as={Link}
                    to='/mobile'
                />
                </Menu>
            </div>
            <div className='footer__item'>
                
                <div className='footer-menu-title'><h4>Quick Links</h4></div>
                <Menu text vertical className='footer-menu'>
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.HOME}
                    active={props.activeItem === TabNames.HOME}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                        props.handleItemClick(event, data);
                        scrollToTop();
                    }}
                    as={Link}
                    to='/'
                />
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.CONTACT}
                    active={props.activeItem === TabNames.CONTACT}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                        props.handleItemClick(event, data);
                        scrollToTop();
                    }}
                    as={Link}
                    to='/contact'
                />
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.ABOUT_US}
                    active={props.activeItem === TabNames.ABOUT_US}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                        props.handleItemClick(event, data);
                        scrollToTop();
                    }}
                    as={Link}
                    to='/about-us'
                />
                </Menu>
            </div>
           
            <div className='footer__item'>
                
                <div className='footer-menu-title'><h4>Company</h4></div>
                <Menu text vertical className='footer-menu'>
         
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.CONTACT}
                    active={props.activeItem === TabNames.CONTACT}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                        props.handleItemClick(event, data);
                        scrollToTop();
                    }}
                    as={Link}
                    to='/contact'
                />
                <Menu.Item
                    className='footer-menu-item'
                    name={TabNames.ABOUT_US}
                    active={props.activeItem === TabNames.ABOUT_US}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                        props.handleItemClick(event, data);
                        scrollToTop();
                    }}
                    as={Link}
                    to='/about-us'
                />
                </Menu>
            </div>
        </div>
        <div className='footer__item2'>
                {/* <img src={img} alt='optimump' height={50} /> */}
                <a href="https://www.quinterrasoft.com/" target="_blank">
                <div className='footer__pvt2'> All Rights Reserved. | Developed by Quinterra Software Solutions Pvt. Ltd.</div></a>
                {/* <div className='footer__pvt'>Email - info@optimump.com</div> */}
                {/* <div>
                H. No : 3-4-32, Vijayasree colony, Autonagar, Hyderabad-
                500070India
                </div> */}
            </div>
      </footer>
    )
}
