import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon, Menu, MenuItemProps } from 'semantic-ui-react'
import logo from '../../images/LogoMain.png'
import './Header.css'

export enum TabNames {
    HOME = 'Home',
    PRODUCTS = "Features",
    MOBILE = "Mobile",
    CONTACT = "Contact",
    DEMO = "Demo",
    ABOUT_US = "About Us"
}

export const Header = (props: {
    activeItem: TabNames,
    handleItemClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => void
}) => {
    const [open, setOpen] = useState<boolean>(false);

    const [navBg, setNavBg] = useState(false);


    const changeNavBg = () => {
        window.scrollY >= 150 ? setNavBg(true) : setNavBg(false);
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNavBg);
        return () => {
            window.removeEventListener('scroll', changeNavBg);
        }
    }, [])

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <div className='top-bar'>
            <a className='logo-link' href='/'>
                <img src={logo} alt='Qshikshak' className='logo' />
                {/* <div className='footer__pvt'>Qshikshak</div> */}
            </a>
            <div className='mobile-bar' onClick={() => {
                setOpen(!open)
            }}>
                <Icon name='bars' sx={{ color: "var(--line)" }} size='big' />
            </div>

            <Menu fixed='top' className={!navBg ? ('header-layout') : ('header-layout active')} stackable>
                <Menu.Menu
                    position='right'
                    className={open ? 'nav-bar-open' : 'nav-bar-hide'}
                >
                    <Menu.Item
                        name={TabNames.HOME}
                        active={props.activeItem === TabNames.HOME}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            // scrollToTop();
                            scrollToTop();
                        }}
                        as={Link}
                        to='/'
                    // className='tab-style'
                    // color=''
                    />
                    <Menu.Item
                        name={TabNames.PRODUCTS}
                        active={props.activeItem === TabNames.PRODUCTS}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/product'
                    // color='red'
                    />
                    <Menu.Item
                        name={TabNames.MOBILE}
                        active={props.activeItem === TabNames.MOBILE}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/mobile'
                    />
                    <Menu.Item
                        name={TabNames.CONTACT}
                        active={props.activeItem === TabNames.CONTACT}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                            scrollToTop();
                        }}
                        as={Link}
                        to='/contact'
                    />
                    <Menu.Item
                        name={TabNames.ABOUT_US}
                        active={props.activeItem === TabNames.ABOUT_US}
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
                            props.handleItemClick(event, data);
                            setOpen(false);
                        }}
                        as={Link}
                        to='/about-us'
                    />
                    <div className='block2'>
                        <a className='text' href='https://app.qshikshak.com' target='_blank'>
                            {/* <img src={logo} alt='Qshikshak' className='logo'/> */}
                            {/* <div className='footer__pvt'>Qshikshak</div> */}
                            Login
                        </a>
                        <div className='hero_button'>
                            <Button primary><a href='/demo' style={{ color: "white", fontWeight: 'bold' }}>Book A Demo</a></Button>
                        </div>
                    </div>

                </Menu.Menu>
            </Menu>

        </div>
    )
}