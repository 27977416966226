import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { Button, Header } from 'semantic-ui-react';
import logoadmision from '../../images/Admission.png';
import bgImage2 from "../../images/BG2.png";
import bgImage3 from "../../images/BG3.png";
import bgImage4 from "../../images/BG4.png";
import Eop from '../../images/EOperations.png';
import logoEams from '../../images/Exams.png';
import logoFee from '../../images/Fee.png';
import logoFee1 from '../../images/Fee1.png';
import Eop2 from '../../images/Se2.png';
import Eop3 from '../../images/TC.png';
import logoTrans from '../../images/Transport.png';
import logo3 from '../../images/appIcon.png';
import logo2 from '../../images/attendanceIcon.png';
import bgImage1 from "../../images/heroBG.png";
import logoWeb from '../../images/logoWeb.png';
import Ds2 from '../../images/sectionui.png';
import Ds3 from '../../images/sectionui2.png';
import logo from '../../images/student3d.png';
import './Home.css';

import { useNavigate } from 'react-router-dom';

export const MottoSection = () => {
  const navigate = useNavigate();

  return (
    <section className='hero' style={{ backgroundImage: `url(${bgImage1})`, backgroundSize: 'cover', background: 'cover' }}>
      <div className='hero__flex' >
        <div className='hero__left' >
          <div className='features'>
            <Button className='featurBlockdown'
              //   onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
              //     // props.handleItemClick(event, data);
              //     setOpen(false);
              // }}
              onClick={() => {
                navigate("/product?q=Class_Student")
              }}
            >

              <img src={logo} alt='Qshikshak' className='icon' />
              <Header as='h2' className='hero_heading1'>
                Class and Subjects <br /> Managment<br />
              </Header>
            </Button>
            <Button className='featurBlockup'
              onClick={() => {
                navigate("/mobile")
              }}
            >
              <img src={logo3} alt='Qshikshak' className='icon' />
              <Header as='h2' className='hero_heading1'>
                Parent <br /> App<br />
              </Header>
            </Button>
          </div>
          <Header as='h1' size='huge' className='hero_heading'>
            WE HELP YOU  MAKE
            {/* <br  />TEACHING SIMPLE<br /> */}
            <h3>
              TEACHING SIMPLE
            </h3>
          </Header>
          <p className='hero_paragraph'>
            Qshikshak helps  Educational Institutions  function easy.
          </p>
          <div className='hero_button'>
            <Button primary><a href='/demo' style={{ color: "white" }}>Book A Demo</a></Button>
          </div>
          <div className='features2'>
            <Button className='featurBlockdown'
              onClick={() => {
                navigate("/product?q=Attendance")
              }}
            >

              <img src={logo2} alt='Qshikshak' className='icon' />
              <Header as='h2' className='hero_heading1'>
                Attendance <br />Report<br />
              </Header>
            </Button>
            <Button className='featurBlockup' onClick={() => {
              navigate("/product")
            }}>
              <img src={logo3} alt='Qshikshak' className='icon' />
              <Header as='h2' className='hero_heading1'>
                More <br />Features<br />
              </Header>
            </Button>
          </div>
        </div>
        {/* <div className='hero__right' style={{ backgroundImage: `url(${bgGround})` }}>
        </div> */}
      </div>
    </section>
  )
}

export const DescriptionSection = () => {
  return (
    <section className="second-section" style={{ backgroundImage: `url(${bgImage2})`, backgroundSize: 'cover', background: 'cover' }}>


      <div className="second-section__right">
        <div className='des_image'>
        <img src={Eop} alt='Qshikshak' className='icon' />
        </div>
        
        <div className="content">
          <h1 className='title'>Efficient Operations</h1>

          <p>
            Automates administrative tasks, enhances communication between stakeholders, <br />
            improves operational efficiency & reduces cost, ultimately leading to better academic outcomes
            and institutional growth.
          </p>
        </div>

      </div>
      <div className="second-section__right2">

        <div className="content">
          <h1 className='title'>Simplified Workload</h1>

          <p>
            Lets teachers take a break from the burden of attendance marking & timetable creation, allowing them
            to dedicate their focus to cultivating an immersive learning environment.
          </p>
        </div>
        <div className='des_image'>
        <img src={Eop2} alt='Qshikshak' className='icon'  />
        </div>
        
      </div>
      <div className="second-section__right">
        <div className='des_image'>
        <img src={Eop3} alt='Qshikshak' className='icon' />
        </div>
        
        <div className="content">
          <h1 className='title'>Transparent Communication</h1>

          <p>
            Automates administrative tasks, enhances communication between stakeholders, <br />
            improves operational efficiency & reduces cost, ultimately leading to better academic outcomes
            and institutional growth.
          </p>
        </div>

      </div>

    </section>
  );
}

export const DescriptionSection2 = () => {
  return (
    <section className="second-section2" style={{ backgroundImage: `url(${bgImage4})`, backgroundSize: 'cover', background: 'cover' }}>
      <div className="second-section__rightui">
        <div>
          <h1 className='title'>Easy Interface</h1>
          {/* <p className='description1'> At OptimumP, we offer good quality 
        <strong>Battery Management System (BMS)</strong>.
         The Battery Management System (BMS) we offer are manufactured under strict quality 
         controls and all the BMS go through high standard Quality Contol before reaching to Indian Market.</p> */}
          <p>
            Improves operational efficiency & reduces cost, ultimately leading to better academic outcomes and institutional growth.
          </p>
        </div>
        {/* <div className='description-button'>
          <Button primary><a href='/product' style={{color: "white"}}>Learn More</a></Button>
        </div> */}
       <img src={Ds3} alt='Qshikshak' className='icon' />
       
      </div>
      <div className="second-section__left">
        <img src={Ds2} alt='Qshikshak' className='icon' />
      </div>


    </section>
  );
}

export const FeaturesSection = () => {

  const navigate = useNavigate();
  return (
    <section className="feature" style={{ backgroundImage: `url(${bgImage3})`, backgroundSize: 'cover', background: 'cover' }}>
      <h1 className="feature__heading">Features</h1>
      <div className='feature__content'>
        <div className="feature__flex">
          <Button className="feature__item"
            onClick={() => {
              navigate("/product?q=Class_Student")
            }}>
            <div className="feature__item-heading">
              <img
                src={logo}
                alt="Class"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Class, Subjects<br></br>Management</strong></h3>
            </div>
            <div className="feature__item-desc">
              <div className='img_wrapper'>
                <img
                  src={logo}
                  alt="Class"
                  className="feature__item-image"
                />
              </div>
              <div className='text_wrapper'>
                <p>
                  Managing classes and subjects<br></br>
                  Create/Manage classes and sections<br></br>
                  Add/Manage subjects<br></br>
                </p>
                <KeyboardDoubleArrowRightOutlinedIcon className='icon'></KeyboardDoubleArrowRightOutlinedIcon>
              </div>
            </div>
          </Button>
          <Button className="feature__item"
            onClick={() => {
              navigate("/product?q=Class_Student")
            }}>
            <div className="feature__item-heading">
              <img
                src={logo2}
                alt="Under Voltage
                Protection
                "
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Attendance<br></br></strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Managing classes and subjects<br></br>
                Create/Manage classes and sections<br></br>
                Add/Manage subjects<br></br>
              </p>

              <KeyboardDoubleArrowRightOutlinedIcon className='icon'></KeyboardDoubleArrowRightOutlinedIcon>

            </div>
            {/* <div className="feature__item-desc">
              <p>
                Protects the battery pack from over-discharging by terminating
                the discharging process, when pack is completely drained
              </p>
            </div> */}
          </Button>
          <Button className="feature__item"
            onClick={() => {
              navigate("/product?q=Class_Student")
            }}>
            <div className="feature__item-heading">
              <img
                src={logoEams}
                alt="Temperature
                Protection"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Examinations<br></br></strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Managing classes and subjects<br></br>
                Create/Manage classes and sections<br></br>
                Add/Manage subjects<br></br>
              </p>

              <KeyboardDoubleArrowRightOutlinedIcon className='icon'></KeyboardDoubleArrowRightOutlinedIcon>

            </div>
            {/* <div className="feature__item-desc">
              <p>
                Over temperature and under temperature protection while
                charging or discharging
              </p>
            </div> */}
          </Button>
          <Button className="feature__item"
            onClick={() => {
              navigate("/product?q=Class_Student")
            }}>
            <div className="feature__item-heading">
              <img
                src={logoadmision}
                alt="Voltage
                Protection-delay"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Admissions<br></br></strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Managing classes and subjects<br></br>
                Create/Manage classes and sections<br></br>
                Add/Manage subjects<br></br>
              </p>

              <KeyboardDoubleArrowRightOutlinedIcon className='icon'></KeyboardDoubleArrowRightOutlinedIcon>

            </div>
            {/* <div className="feature__item-desc">
              <p>
                Over voltage protection and Undervoltage protections are as
                fast as 0.5 Seconds
              </p>
            </div> */}
          </Button>
        </div>
        <div className="feature__flex">
          <Button className="feature__item"
            onClick={() => {
              navigate("/product?q=Class_Student")
            }}>
            <div className="feature__item-heading">
              <img
                src={logoFee}
                alt="Power
                consumption"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Fee<br></br>Management</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Managing classes and subjects<br></br>
                Create/Manage classes and sections<br></br>
                Add/Manage subjects<br></br>
              </p>

              <KeyboardDoubleArrowRightOutlinedIcon className='icon'></KeyboardDoubleArrowRightOutlinedIcon>

            </div>
            {/* <div className="feature__item-desc">
              <p>
                During normal operation BMS consumes as low as 4 micro amperes
                and system goes to sleep under no load condition
              </p>
            </div> */}
          </Button>
          <Button className="feature__item"
            onClick={() => {
              navigate("/product?q=Class_Student")
            }}>
            <div className="feature__item-heading">
              <img
                src={logoTrans}
                alt="Cell
                Balancing"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Transport<br></br>Management</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Managing classes and subjects<br></br>
                Create/Manage classes and sections<br></br>
                Add/Manage subjects<br></br>
              </p>

              <KeyboardDoubleArrowRightOutlinedIcon className='icon'></KeyboardDoubleArrowRightOutlinedIcon>

            </div>
            {/* <div className="feature__item-desc">
              <p>
                Passive balancing and Active balancing is done based on
                intelligent algorithm. Balancing currents up to 50mA and more
                is possible
              </p>
            </div> */}
          </Button>
          <Button className="feature__item"
            onClick={() => {
              navigate("/product?q=Class_Student")
            }}>
            <div className="feature__item-heading">
              <img
                src={logoFee1}
                alt="Over current
                protection"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Finance &<br></br>Expenses</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Managing classes and subjects<br></br>
                Create/Manage classes and sections<br></br>
                Add/Manage subjects<br></br>
              </p>

              <KeyboardDoubleArrowRightOutlinedIcon className='icon'></KeyboardDoubleArrowRightOutlinedIcon>

            </div>
            {/* <div className="feature__item-desc">
              <p>
                Over current protection is triggered in just 18milli seconds
                when threshold limit is reached
              </p>
            </div> */}
          </Button>
          <Button className="feature__item"
            onClick={() => {
              navigate("/product?q=Class_Student")
            }}>
            <div className="feature__item-heading">
              <img
                src={logoWeb}
                alt="Over current
                protection"
                className="feature__item-image"
              />
              <h3><strong className="bold-text-2">Website<br></br>Management</strong></h3>
            </div>
            <div className="feature__item-desc">
              <p>
                Managing classes and subjects<br></br>
                Create/Manage classes and sections<br></br>
                Add/Manage subjects<br></br>
              </p>

              <KeyboardDoubleArrowRightOutlinedIcon className='icon'></KeyboardDoubleArrowRightOutlinedIcon>

            </div>
            {/* <div className="feature__item-desc">
              <p>
                Resettable short-circuit protection just like MCB without any
                damage to the pack
              </p>
            </div> */}
          </Button>
        </div>
      </div>
    </section>
  );
}

export const Home = () => {
  return (
    <>
      <MottoSection />
      <DescriptionSection />
      <DescriptionSection2 />
      <FeaturesSection />
    </>
  )
}
