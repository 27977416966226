import { Header } from 'semantic-ui-react'
import './Mobile.css'
import { MobileTab } from './MobileTab'

export const Mobile = () => {
  return (
    <div className='mobile' style={{
      backgroundColor: 'rgba(70, 61, 163, 1)'
    }}>
      <div
        className='mobile__top'
        
      >
        <Header as={'h1'} size='huge'>
      Mobile Features
        </Header>
      </div>
      <div className='mobile__bottom'>
        <MobileTab/>
      </div>
      
    </div>
  )
}