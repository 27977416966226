import { useMediaQuery } from '@mui/material';
import Lottie from "lottie-react";
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Header, MenuItem, Tab } from 'semantic-ui-react';
import logoadmision from '../../images/Admission.png';
import AF1 from "../../images/AttendenceF1.png";
import logoBio from '../../images/Bio.png';
import logoE from '../../images/Event.png';
import FEvents from '../../images/Events1.png';
import logoEams from '../../images/Exams.png';
import Expens from '../../images/Expens.png';
import logoFee from '../../images/Fee.png';
import logoFee1 from '../../images/Fee1.png';
import logoFee2 from '../../images/Fee2.png';
import logoFee3 from '../../images/Fee3.png';
import FeeColl from '../../images/FeeColl.png';
import FeeDue from '../../images/FeeDue.png';
import FeeManag from '../../images/FeeMang.png';
import inventory from '../../images/InventoryA.png';
import logoInv from '../../images/InventoryB.png';
import logoPayment from '../../images/Payment.png';
import logoPayment1 from '../../images/Payment2.png';
import logoReport from '../../images/Report.png';
import Reports from '../../images/Reports.png';
import logoSMS from '../../images/SMS.png';
import logoSMS1 from '../../images/SMS1.png';
import SF2 from '../../images/StudentF2.png';
import SF1 from '../../images/StudentsF1.png';
import Ttable from '../../images/TimetabeImage.png';
import Track from '../../images/Tracks.png';
import logoTrans from '../../images/Transport.png';
import Vmanagment from '../../images/VMangment.png';
import logoVisitor from '../../images/Visitor.png';
import websitePic from "../../images/WebsiteMang.png";
import logoWhatsapp from '../../images/Whatsapp.png';
import logoWhatsapp1 from '../../images/Whatsapp1.png';
import logo2 from '../../images/attendanceIcon.png';
import biomaric from '../../images/biom.png';
import logoEams2 from '../../images/examsGrphs.jpeg';
import logoWeb from "../../images/logoWeb.png";
import workingLottie from "../../images/lotties/Exams.json";
import logo from '../../images/student3d.png';
import logoT from '../../images/timetable.png';
import './Product.css';


export const ProductsTab = () => {

  let [searchParams, setSearchParams] = useSearchParams();
  const [activetab,setActiveTab] = useState('');

  const [activeIndex,setActiveIndex] = useState(1);

  let isMobileView = useMediaQuery("(max-width:900px)")

  const scrollToTop = () => {    
    if (isMobileView) {
      window.scrollTo(0,800);
    } else {
      window.scrollTo(0,300);
    }
   }

   const panes = [
    {
      menuItem: (<MenuItem key='Exam' className='item1 heading' disabled >
        {/* <img src={logo3} alt='Qshikshak' className='icon'/> */}
        <div> Management <br></br>
        </div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
  
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='class' className='item1' name='Class_Student' onClick={() =>{scrollToTop()
         setActiveIndex(1)
      setSearchParams({q:'Class_Student'})
      // scrollToTop()
      }} >
        <img src={logo} alt='Qshikshak' className='icon' />
        <div> Class & Students <br></br>
          Management</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Management</div>
                  <div className='title'>Class & Student Management</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      Managing classes and subjects<br></br>
                      Create/Manage classes and sections<br></br>
                      Assign teachers to classes.<br></br>
                      Add/Manage subjects and additional subjects.<br></br>
                      Arrange the subjects in any order for printing in report card.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={SF1} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
              <div className='bms-item'>
                <div className='tab__btn__flex'>
                  <img src={SF2} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
                <div>
                  {/* <div className='sub-title'>Management</div> */}
                  <div className='title'>Student Profile</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      Admin can assign Classes and sections to the teachers <br></br>
                      through our module. Principal can set up a new class by providing <br></br>
                      a class name, a code and department. The curriculum and class strength <br></br>
                      will be displayed.They can also check and amend the students' list of classes and sections.
                    </Header>
                  </div>
                </div>
  
              </div>
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='timetable' className='item1' name='Timetable' active={activetab === 'Timetable' } onClick={() =>{scrollToTop()
         setActiveIndex(2)
        setSearchParams({q:'Timetable'})
        }} >
        <img src={logoT} alt='Qshikshak' className='icon' />
        <div> Timetable <br></br>
          </div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Management</div>
                  <div className='title'>Timetable</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      
                      With the help of school timetable management software, <br></br>
                      educational establishments of all sizes may create and manage <br></br>
                      timetables for particular courses with ease. It simplifies the <br></br>
                      assignment of teachers and classrooms, makes it easier to assign substitutes, <br></br>
                      and effectively manages daily and monthly class schedules in a digital format.<br></br>
                      Furthermore, it autonomously verifies the alignment of faculty schedules and <br></br>
                       room availability, ensuring a harmonious flow of operations.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={Ttable} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Attendance' className='item1' name='Attendance' active={activetab === 'Attendance'}  onClick={() =>{scrollToTop()
         setActiveIndex(3)
        setSearchParams({q:'Attendance'})
        }} >
        <img src={logo2} alt='Qshikshak' className='icon' />
        <div>Attendance <br></br>
          Management</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Management</div>
                  <div className='title'>Attendance Management</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                  

                      Attendance module provides daily tracking of employee <br></br>
                      and students attendance.  It helps to manage entire <br></br>
                      attendance records centrally. Class teacher can enter <br></br>
                      the attendance directly through teacher mobile app.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={AF1} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Events' className='item1' name='Events' active={activetab === 'Events'}  onClick={() =>{scrollToTop()
         setActiveIndex(4)
        setSearchParams({q:'Events'})
        }}>
        <img src={logoE} alt='Qshikshak' className='icon' />
        <div> Events<br></br>
          Calender</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Management</div>
                  <div className='title'>Events and Calender</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      Students can check regular circulars to see if <br></br>
                      there are any upcoming activities, picnics, or educational<br></br>
                       visits; professors will post the circulars to the system, <br></br>
                       and students can view them by a specific date.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={FEvents} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
          
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Exam' className='item1' name='Exams' active={activetab === 'Exams'}  onClick={() =>{scrollToTop()
         setActiveIndex(5)
        setSearchParams({q:'Exams'})
        }} >
        <img src={logoEams} alt='Qshikshak' className='icon' />
        <div> Exams <br></br>
          Management</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Management</div>
                  <div className='title'>Exam Management</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    

                      Examinations module is designed to assist teachers to <br></br>
                      create the exams as per the need, preparing Exam papers.<br></br>
                      Principal can follow up the question paper and performance<br></br>
                       of the students through class wise and subject wise report <br></br>
                        card. It ensure a smooth experience for both institution and students.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex2'>
                  <img src={logoEams2} alt='Qshikshak' className='icon2' />
                  <Lottie animationData={workingLottie} className="lottie" loop={true} />;
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Inventory' className='item1' name='Inventory' active={activetab === 'Inventory'}  onClick={() =>{scrollToTop()
         setActiveIndex(6)
        setSearchParams({q:'Inventory'})
        }} >
        <img src={logoInv} alt='Qshikshak' className='icon' />
        <div> Inventory <br></br>
          Management</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Management</div>
                  <div className='title'>Inventory Management</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                     
                      One of the best choices you can make as a bespoke <br></br>
                      maker is to use an ERP system for inventory management.<br></br>
                      You'll save time by not only avoiding the mistakes made <br></br>
                      by people when utilizing spreadsheets, like incorrect or <br></br>
                      missing data, but you'll also be able to skip the <br></br>
                      time-consuming process of manually updating and balancing <br></br>
                      inventories. Your inventory will be automatically integrated<br></br>
                       with all aspects of your business, including planning, operations,<br></br>
                        manufacturing, and accounting, if you use an ERP system. <br></br>
                        With only one system to handle everything, your business<br></br>
                         will be more streamlined, efficient, and well-organized.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                <img src={inventory} alt='Qshikshak' className='icon' />
                  {/* <img src={SF1} alt='Qshikshak' className='icon2' /> */}
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='reports' className='item1' name='Reports' active={activetab === 'Reports'}  onClick={() =>{scrollToTop()
         setActiveIndex(7)
        setSearchParams({q:'Reports'})
        }} >
        <img src={logoReport} alt='Qshikshak' className='icon' />
        <div> Report <br></br>
          Generation</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Management</div>
                  <div className='title'>Report Generation</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    In this module we can generate variety of reports <br></br>
                    such as attendance, marks, etc.<br></br>
                    Category Wise reports, Religion Wise Reports, etc.<br></br>
                     which need to be submitted to govt. can be generated.<br></br>
                      Arrange the subjects in any order for printing in report card.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={Reports} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Visitor' className='item1' name='Visitor' active={activetab === 'Visitor'}  onClick={() =>{scrollToTop()
         setActiveIndex(8)
        setSearchParams({q:'Visitor'})
        }} >
        <img src={logoVisitor} alt='Qshikshak' className='icon' />
        <div> Visitor <br></br>
          Management</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Management</div>
                  <div className='title'>Visitor Management</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                   
                      Visitor management systems (VMS) allow organizations to <br></br>
                      efficiently welcome, keep track of, and manage the visitors <br></br>
                      of a building, facility, office, place of business, or other location.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={Vmanagment} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Transport' className='item1' name='Transport' active={activetab === 'Transport'}  onClick={() =>{scrollToTop()
         setActiveIndex(9)
        setSearchParams({q:'Transport'})
        }} >
        <img src={logoTrans} alt='Qshikshak' className='icon' />
        <div> Transport<br></br>
          Management</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Management</div>
                  <div className='title'>Transport Management</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    Transportation management is crucial in schools. The addition of various <br></br>
                    transport vehicles and driver information, including Name,<br></br>
                     Vehicle Number, License Number, and Driver's Phone Number,<br></br>
                      is entirely at the admin's control. With the designated <br></br>
                      vehicle number, it can allocate and oversee different routes<br></br> 
                      to different drivers. Comprehensive examination of <br></br>
                      transportation allocation will be provided in this <br></br>
                      session. To provide the user with the exact location <br></br>
                      of their vehicle at any point in time, it makes use of a number of features.<br></br>
                      
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={Track} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
              </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Admission' className='item1' name='Admission' active={activetab === 'Admission'}  onClick={() =>{scrollToTop()
         setActiveIndex(10)
        setSearchParams({q:'Admission'})
        }} >
        <img src={logoadmision} alt='Qshikshak' className='icon' />
        <div> Admission <br></br>
          Management</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Management</div>
                  <div className='title'>Admission Management</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      Admission module makes this process very easy in order <br></br>
                      to handle the new student data without paper work. <br></br>
                      One can quickly access the data of the student in one place. <br></br>
                      System assigns a unique Id to each and every student
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={SF1} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Finance' className='item1  heading' disabled >
        {/* <img src={logo3} alt='Qshikshak' className='icon'/> */}
        <div> Finance <br></br>
        </div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
  
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='FeeMangment' className='item1' name='FeeMangment' active={activetab === 'FeeMangment'}  onClick={() =>{scrollToTop()
         setActiveIndex(12)
        setSearchParams({q:'FeeMangment'})
        }} >
        <img src={logoFee1} alt='Qshikshak' className='icon' />
        <div> Fee <br></br>
          Management</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Finance</div>
                  <div className='title'>Fee Management</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      Fee management module makes the fee collection hassle free,<br></br>
                      manages the fee related actions, customize fee plans for various <br></br>
                      functions such as admission, programs, services, exams, etc.<br></br>
                      Admin can track the defaulters and send custamized notices to students. 
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={FeeManag} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Fee_collection' className='item1' name='Fee_collection' active={activetab === 'Fee_collection'}  onClick={() =>{scrollToTop()
         setActiveIndex(13)
        setSearchParams({q:'Fee_collection'})
        }} >
        <img src={logoFee} alt='Qshikshak' className='icon' />
        <div> Fee <br></br>
          Collection</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Finance</div>
                  <div className='title'>Fee Collection</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      Instant Fee Collection<br></br>
                      All types of Fee Collection online and offline<br></br>
                      Fee Recipet Generation<br></br>
                      View all ypes of Fee generation<br></br>
                    
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={FeeColl} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='FeeReport' className='item1' name='Fee_Report' active={activetab === 'Fee_Report'}  onClick={() =>{scrollToTop()
         setActiveIndex(14)
        setSearchParams({q:'Fee_Report'})
        }}>
        <img src={logoFee3} alt='Qshikshak' className='icon' />
        <div>Fee Due <br></br>
          Reports</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Finance</div>
                  <div className='title'>Reports</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                    This reports give detailed report on the fee due list <br></br>
                    of students of selected class as on date<br></br>
                    If the filter is kept based on Fee type, <br></br>
                    then fee amount due against the fee type would <br></br>
                    be shown for all the students<br></br>
                     
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={FeeDue} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='Expense' className='item1'  name='Expense' active={activetab === 'Expense'}  onClick={() =>{scrollToTop()
         setActiveIndex(15)
        setSearchParams({q:'Expense'})
        }}>
        <img src={logoFee2} alt='Qshikshak' className='icon' />
        <div> Expense<br></br>
          Management</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Finance</div>
                  <div className='title'>Expense Management</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                     
                      Expense management is a book-keeping system for controlling, <br></br>
                      tracking, and processing employee expense reimbursements and <br></br>
                      employee-related corporate credit card transactions. Specialized <br></br>
                      online and mobile app expense management software integrates <br></br>
                      with accounting software and ERP systems.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={Expens} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='addons' className='item1  heading' disabled >
        {/* <img src={logo3} alt='Qshikshak' className='icon'/> */}
        <div> Add Ons <br></br>
        </div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
  
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='sms' className='item1'  name='Sms' active={activetab === 'Sms'}  onClick={() =>{scrollToTop()
         setActiveIndex(17)
        setSearchParams({q:'Sms'})
        }} >
        <img src={logoSMS} alt='Qshikshak' className='icon' />
        <div> SMS <br></br>
          System</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Addons</div>
                  <div className='title'>SMS Sysyem</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      Mass Text Messaging for Schools.<br></br>
                      Bulk SMS messaging for schools provides a <br></br>
                       popular communication channel to quickly convey important <br></br>
                       messages to groups of students, parents and faculty.<br></br>
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={logoSMS1} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='whatsapp' className='item1'  name='whatsapp' active={activetab === 'whatsapp'}  onClick={() =>{scrollToTop()
         setActiveIndex(18)
        setSearchParams({q:'whatsapp'})
        }} >
        <img src={logoWhatsapp} alt='Qshikshak' className='icon' />
        <div> Whatsapp <br></br>
          Integration</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Addons</div>
                  <div className='title'>Whatsapp  Integration</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      WhatsApp can be used as a communication tool <br></br>
                      between teachers, parents and school management. It is <br></br>
                      an excellent way to keep everyone informed about <br></br>
                      class schedules, upcoming assignments, and other important information.
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={logoWhatsapp1} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
           
  
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='biomatric' className='item1'  name='biomatric' active={activetab === 'biomatric'}  onClick={() =>{scrollToTop()
         setActiveIndex(19)
        setSearchParams({q:'biomatric'})
        }} >
        <img src={logoBio} alt='Qshikshak' className='icon' />
        <div> Biometric <br></br>
          Integration</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Addons</div>
                  <div className='title'>Biometric  Integration</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      For a hassle free attendance, Qshishak provides biometric integration<br></br>
                      on top of the existing attendance module.<br></br>
                      In this add on biometric systems using face scanning, finger print scanning<br></br>
                      and id card scanning are supported. All the data is reflected centrally in real-time.<br></br>
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={biomaric} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
              
  
            </div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (<MenuItem key='payment' className='item1'  name='payment' active={activetab === 'payment'}  onClick={() =>{scrollToTop()
         setActiveIndex(20)
        setSearchParams({q:'payment'})
        }} >
        <img src={logoPayment} alt='Qshikshak' className='icon' />
        <div> Payment <br></br>
          Integration</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Addons</div>
                  <div className='title'>Payment  Integration</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                  
                      Education payment platform allows students or <br></br>
                      their parents to pay fees with any payment option<br></br>
                       of their choice. Once the payment is made, <br></br>
                       the amount gets credited to the bank account. 
                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={logoPayment1} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
            </div>
          </div>
        </Tab.Pane>
      ),
    },    
    {
      menuItem: (<MenuItem key='WebsiteManagement' className='item1' name='WebsiteManagement' active={activetab === 'WebsiteManagement'}  onClick={() =>{scrollToTop()
         setActiveIndex(21)
        setSearchParams({q:'WebsiteManagement'})
        }} >
        <img src={logoWeb} alt='Qshikshak' className='icon' />
        <div> Website<br></br>
          Management</div>
  
      </MenuItem>),
      render: () => (
        <Tab.Pane>
          <div className='tab'>
            <div className='bms-section'>
              <div className='bms-item'>
                <div>
                  <div className='sub-title'>Add Ons</div>
                  <div className='title'>Website Management</div>
  
                  <div className='specification'>
                    {/* <Header as={'h4'} size='small'>
                    Specifications:
                  </Header> */}
                    <Header as={'h4'} size='small' >
                      We design, develop and deploy websites for the schools<br></br>
                      in a fully automated way. <br></br>
                      Management can update the gallery from the portal directly. <br></br>

                    </Header>
                  </div>
                </div>
                <div className='tab__btn__flex'>
                  <img src={websitePic} alt='Qshikshak' className='icon' />
                  {/* <Button primary><a href='#product-description' style={{color: "white"}}>Learn More</a></Button> */}
                </div>
              </div>
             </div>
          </div>
        </Tab.Pane>
      ),
    },
  ]
  
  const tabList = [
    "",
    "Class_Student",
    "Timetable",
    "Attendance",
    "Events",
    "Exams",
    "Inventory",
    "Reports",
    "Visitor",
    "Transport",
    "Admission",
    "",
    "FeeMangment",
    "Fee_collection",
    "Fee_Report",
    "Expense",
    "",
    "Sms",
    "whatsapp",
    "biomatric",
    "payment",
    "WebsiteManagement"
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const q = searchParams.get('q')

    if (q){
      setActiveTab(q)
      const index = tabList.indexOf(q)
      setActiveIndex(index)
    }
  }, [searchParams, tabList])

  return (
    <Tab activeIndex={activeIndex}
      panes={panes} />
  )
}